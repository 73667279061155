import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";


import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import styles from "yet-another-react-lightbox/styles.css";


const ImageCarousal = (props) => {

    const fullscreenRef = React.useRef(null);
    const thumbnailsRef = React.useRef(null);
    const zoomRef = React.useRef(null);

    return(
        <>
        <Lightbox
    plugins={[Inline,Fullscreen, Thumbnails, Zoom]}
    fullscreen={{ ref: fullscreenRef }}
    thumbnails={{ ref: thumbnailsRef }}
    zoom={{ ref: zoomRef }}
    styles={{container: {backgroundColor:'white', border:'1px solid gray'},thumbnailsContainer:{backgroundColor:'black'}, thumbnail:{backgroundColor:"white"}, button:{color:'transparent', backgroundColor:'transparent'}, icon:{color:'black'}}}
    on={{
        click: () => {
        
            fullscreenRef.current?.enter();
            (thumbnailsRef.current?.visible
                ? thumbnailsRef.current?.hide
                : thumbnailsRef.current?.show)?.();
        },
      }}
    
    inline={{
      style: { width: "100%", maxWidth: "900px", aspectRatio: "3 / 2", },
    }}
    
    slides={props.imageUrls}
  />
 
  </>
    )
}

export default ImageCarousal