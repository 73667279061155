import React, {useEffect, useState} from "react";
import {Navbar, Container, Row, Col, Card, Table, Image} from "react-bootstrap";
import ImageCarousel from "./ImageCarousal";
import {useSearchParams} from 'react-router-dom';


const TireDetails = (props) => {

    const [searchParams] = useSearchParams();

    const [partnumber] = useState(searchParams.get("partnumber"))
    const [tireData, setTireData] = useState({})
    const [imageUrls, setImageUrls] = useState([])

    const [brandUrl, setBrandUrl] = useState("")

    useEffect(()=>{
        if(partnumber){
            fetch(process.env.REACT_APP_API_URL+ "/get-tire-details-by-partnumber?partnumber="+partnumber, {
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_API_KEY
                  },
            }).then((res)=>{
                if(res.status===200){
                    const json = res.json()
                    json.then((data)=>{
                        setTireData(data)
                        var imageUrlsTemp = []
                        for(var key in data){
                            if(key.startsWith("s3") && key!=="s3ImgThumb"){
                                imageUrlsTemp.push({src: data[key]})
                                setImageUrls(imageUrlsTemp)
                            }
                        }
                        fetch(process.env.REACT_APP_API_URL+"/get-brand-by-id?brand_id="+data["BrandId"], {
                            method:'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-API-Key': process.env.REACT_APP_API_KEY
                              },
                        }).then((res)=>{
                            if(res.status===200){
                                res.json().then(bData=>{
                                    setBrandUrl(bData["s3Key"])
                                })
                            }
                        })
                    })
                }
            })
        }
    },[])

    return(
        <>
        
      <Container style={{marginTop:'1vh', marginBottom:'5vh'}}>
        <Row>
            <Col md={8}>
                <ImageCarousel imageUrls = {imageUrls} />
                <Card style={{marginTop:'2.5vh'}}>
                <Card.Title style={{padding:'15px', paddingBottom:'0px'}}>Benefits</Card.Title>
                <Card.Body style={{padding:'0'}}>
                    {
                       
                            <ul>
                              {tireData["Benefits"]?tireData["Benefits"].toString().split('*').map((item, index) => (
                               item!==""? <li key={index}>{item.trim()}</li>:null
                               
                              )):null}
                            </ul>
                    
                    }
                </Card.Body>
                </Card>
                <Card style={{marginTop:'2.5vh', marginBottom:'2.5vh'}}>
                <Card.Title style={{padding:'15px', paddingBottom:'0px'}}>Features</Card.Title>
                <Card.Body style={{padding:'0'}}>{
                       
                       <ul>
                         {tireData["Features"]?tireData["Features"].toString().split('*').map((item, index) => (
                          item!==""? <li key={index}>{item.trim()}</li>:null
                          
                         )):null}
                       </ul>
               
               }</Card.Body>
                </Card>
            </Col>
            <Col md={4}>
            <Card>
                <Card.Title className="text-center">
                    <Image src={brandUrl} height={64} ></Image>
                </Card.Title>
                <Card.Body>
                <Table striped bordered hover>
    
      <tbody>
      <tr>
          
          <td>Part Number</td>
          <td>{tireData["PartNumber"]}</td>
        </tr>
       {tireData["DisplayName"]? <tr>
          
          <td>Display Name</td>
          <td>{tireData["DisplayName"]}</td>
        </tr>:null}
       
      
      {tireData["LoadCapacityDual"]? <tr>
          
          <td>Load Capacity Dual</td>
          <td>{tireData["LoadCapacityDual"]}</td>
        </tr>:null}
       
       {tireData["LoadCapacitySingle"]? <tr>
          
          <td>Load Capacity Single</td>
          <td>{tireData["LoadCapacitySingle"]}</td>
        </tr>:null}
       
       {tireData["LoadRating"]?<tr>
          
          <td>Load Rating</td>
          <td>{tireData["LoadRating"]}</td>
        </tr>:null}
        
        {tireData["MaxInflationPressure"]?<tr>
          
          <td>Max Inflation Pressure</td>
          <td>{tireData["MaxInflationPressure"]}</td>
        </tr>:null}
        
        {tireData["Model"]? <tr>
          
          <td>Model</td>
          <td>{tireData["Model"]}</td>
        </tr>:null}
       
       {tireData["OverallDiameter"]? <tr>
          
          <td>Overall Diameter</td>
          <td>{tireData["OverallDiameter"]}</td>
        </tr>:null}
       
       {tireData["OverallWidth"]?<tr>
          
          <td>Overall Width</td>
          <td>{tireData["OverallWidth"]}</td>
        </tr>:null}
        
       {tireData["PlyRating"]? <tr>
          
          <td>Ply Rating</td>
          <td>{tireData["PlyRating"]}</td>
        </tr>:null}
       
       {tireData["RimDiameter"]? <tr>
          
          <td>Rim Diameter</td>
          <td>{tireData["RimDiameter"]}</td>
        </tr>:null}
       
       {tireData["RollingCircumference"]?<tr>
          
          <td>Rolling Circumference</td>
          <td>{tireData["RollingCircumference"]}</td>
        </tr>:null}
        
        {tireData["Sidewall"]?  <tr>
          
          <td>Sidewall</td>
          <td>{tireData["Sidewall"]}</td>
        </tr>:null}
      
      {tireData["Size"]? <tr>
          
          <td>Size</td>
          <td>{tireData["Size"]}</td>
        </tr>:null}
       
       {tireData["SpeedRating"]?<tr>
          
          <td>Speed Rating</td>
          <td>{tireData["SpeedRating"]}</td>
        </tr>:null}
        
        {tireData["StaticLoadedRadius"]? <tr>
          
          <td>Static Loaded Radius</td>
          <td>{tireData["StaticLoadedRadius"]}</td>
        </tr>:null}
       
       {tireData["StaticLoadedRadius"]? <tr>
          
          <td>Static Loaded Radius</td>
          <td>{tireData["StaticLoadedRadius"]}</td>
        </tr>:null}
       
       {tireData["TraCode"]?<tr>
          
          <td>Tra Code</td>
          <td>{tireData["TraCode"]}</td>
        </tr>:null}
        
        {tireData["TreadDepth"]? <tr>
          
          <td>Tread Depth</td>
          <td>{tireData["TreadDepth"]}</td>
        </tr>:null}
       
       {tireData["TreadWidth"]?<tr>
          
          <td>TreadWidth</td>
          <td>{tireData["TreadWidth"]}</td>
        </tr>:null}
        
     {tireData["Warranty"]? <tr>
          
          <td>Warranty</td>
          <td>{tireData["Warranty"]}</td>
        </tr>:null}
       {tireData["Weight"]? <tr>
          
          <td>Weight</td>
          <td>{tireData["Weight"]}</td>
        </tr>:null}
       
      </tbody>
    </Table>
                </Card.Body>
            </Card>
            </Col>
        </Row>
      </Container>
      </>
    )
}

export default TireDetails