import React from 'react';
import TireDetails from './dk_tire_library/TireDetails';

function App() {
  return (
    <TireDetails />
  );
}

export default App;
